import { GetServerSideProps, NextPage } from 'next';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import JoinLogin from 'src/components/JoinLogin';
import StandardHead from 'src/components/StandardHead';
import { getMetaInformation, MetaInformation } from 'src/utils/metaInformation';
import { defaultLocale, Locale } from '../locales';
import { doAuthUser } from '../redux/modules/auth/actions';
import { AppState } from '../redux/reducers';

export type JoinNextProps = {
  pathname: string;
  locale: Locale;
  meta: MetaInformation;
  ccgid?: string;
  resume?: boolean;
  slug: string;
  redirectUrl?: string;
};

export type JoinProps = JoinReducerProps & JoinNextProps;

export const PageJoin: NextPage<JoinProps, JoinNextProps> = ({
  pathname,
  syncing,
  doAuthUser,
  meta,
  ccgid,
  resume,
  slug,
  redirectUrl,
  redirecting
}) => {
  useEffect(() => {
    doAuthUser(pathname, ccgid, resume);
  }, []);

  const { title, description, url, image } = meta.og;

  return (
    <JoinLogin redirecting={redirecting} syncing={syncing} slug={slug} ccgid={ccgid} redirectUrl={redirectUrl}>
      <StandardHead
        {...{
          title,
          customTabTitleKey: 'signup.pageTabTitle',
          description,
          url,
          image,
          twitter_card: meta.twitter.card
        }}
      />
    </JoinLogin>
  );
};

export const getServerSideProps: GetServerSideProps<JoinNextProps> = async ctx => {
  const { query, locale = defaultLocale() } = ctx;
  const pathname = query.redirectUrl?.toString() || `/${locale}/cockpit`;
  const redirectUrl = query.redirectUrl?.toString() || '';
  const ccgid = query.ccgid?.toString() || '';
  const resume = typeof query.resume !== 'undefined';

  const meta = await getMetaInformation(pathname, locale as Locale);
  return {
    props: {
      slug: ctx.resolvedUrl,
      pathname,
      meta,
      locale: locale as Locale,
      ccgid,
      resume,
      redirectUrl
    }
  };
};

export const mapStateToPropsJoin = ({ userReducer }: AppState) => ({
  syncing: userReducer.syncing,
  redirecting: userReducer.redirecting
});
export const mapDispatchToPropsJoin = (dispatch: Dispatch) => bindActionCreators({ doAuthUser }, dispatch);

type JoinReducerProps = ReturnType<typeof mapStateToPropsJoin> & ReturnType<typeof mapDispatchToPropsJoin>;

export default connect(mapStateToPropsJoin, mapDispatchToPropsJoin)(PageJoin);
